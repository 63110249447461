import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'tableRowObject'
})
export class TableRowObjectPipe implements PipeTransform {

  transform(tableRowObject: object, objectProperties: string[]): string {
    return objectProperties.map(prop => tableRowObject[prop]).join(" ");
  }

}
