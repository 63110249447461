import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'vex-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  constructor(
      @Inject(MAT_DIALOG_DATA) private data: {title: string, message: string},
      private dialogRef: MatDialogRef<ConfirmComponent>,
  ) {}

  getMessage() {
    return this.data.message;
  }

  getTitle() {
    return this.data.title;
  }

  close(success: boolean) {
    this.dialogRef.close(success);
  }

}
