import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {RegisterClientRequest} from "../model/register-client-request";
import {SnackBarService} from "../../service/snack-bar.service";
import {ErrorResponse} from "../../model/error-response";
import {CustomValidator} from "../../validators/custom-validator";
import {AdminClientApiService} from "../../client/service/admin-client-api.service";
import {Client} from "../../client/model/client";

@Component({
    selector: 'vex-register-client',
    templateUrl: './register-client.component.html',
    styleUrls: ['./register-client.component.scss'],
})
export class RegisterClientComponent implements OnInit {
    form: UntypedFormGroup;

    inputType = 'password';
    visible = false;
    formHidden = false;
    isSubmitted = false;
    loginUrl = '';

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private adminClientApiService: AdminClientApiService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
                name: ['', Validators.required],
                domain: ['', [Validators.required, Validators.pattern(new RegExp('^[a-z0-9]*$'))]],
                nip: ['', [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    CustomValidator.isDigit
                ]],
                accountingOffice: [false],
                email: ['', [Validators.required, Validators.email]],
                phone: ['', [
                    Validators.required,
                    Validators.minLength(9),
                    Validators.maxLength(9),
                    CustomValidator.isDigit
                ]],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                password: ['', [
                    Validators.required,
                    Validators.minLength(8),
                    CustomValidator.upperCase,
                    CustomValidator.lowerCase,
                    CustomValidator.hasDigit,
                    CustomValidator.special
                ]],
                regulationAcceptance: [false, Validators.requiredTrue],
            },
            {
                validators: [CustomValidator.containsOtherFieldValue('password', 'domain')]
            });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }

        this.isSubmitted = true;

        const formValue = this.form.getRawValue();
        const requestData = new RegisterClientRequest(formValue);

        this.adminClientApiService.addClient(requestData).subscribe({
                next: (client: Client) => {
                    this.prepareLoginUrl(client);
                    this.isSubmitted = false;
                    this.formHidden = true;
                },
                error: (data: ErrorResponse) => {
                    this.snackBarService.show(data.error.message);
                    this.isSubmitted = false;
                }
            }
        );
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    prepareLoginUrl(client: Client) {
        this.loginUrl = `${window.location.protocol}//${client.domain}.${window.location.host}`;
    }
}
