import {VexConfig} from "../../@vex/config/vex-config.interface";
import {ColorSchemeName} from "../../@vex/config/colorSchemeName";
import {colorVariables} from "../../@vex/components/config-panel/color-variables";
import {VexConfigName} from "../../@vex/config/config-name.model";

export const layoutConfig: VexConfig = {
    id: VexConfigName.velico,
    name: 'Velico',
    style: {
        colorScheme: ColorSchemeName.default,
        colors: {
            primary: colorVariables.blue
        },
        borderRadius: {
            value: 0.5,
            unit: 'rem'
        },
        button: {
            borderRadius: {
                value: 9999,
                unit: 'px'
            }
        }
    },
    direction: 'ltr',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/apollo.png',
    layout: 'horizontal',
    boxed: false,
    sidenav: {
        title: 'Velico',
        imageUrl: 'assets/velico_logo_small.png',
        showCollapsePin: true,
        user: {
            visible: false
        },
        search: {
            visible: false
        },
        state: 'collapsed'
    },
    toolbar: {
        fixed: true,
        user: {
            visible: true
        }
    },
    navbar: {
        position: 'below-toolbar'
    },
    footer: {
        visible: true,
        fixed: false
    }
};
