<div class="w-full h-full flex flex-col">
    <div class="px-gutter pt-6 pb-20 bg-primary/10 flex-none">
        <div class="flex items-center container px-0">
            <button (click)="openMenu()" @scaleIn class="sm:hidden text-primary-contrast" mat-icon-button type="button">
                <mat-icon svgIcon="mat:menu"></mat-icon>
            </button>
            <h2 class="display-1 font-bold m-0 flex items-center w-full">
        <span @scaleIn
              class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 bg-primary/10 hidden sm:flex items-center justify-center">
          <mat-icon svgIcon="mat:contacts"></mat-icon>
        </span>
                <span @fadeInRight class="block">{{ title }}</span>
            </h2>

            <div class="hidden sm:flex items-center bg-foreground rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-xl mx-auto">
                <mat-icon class="text-secondary flex-none" svgIcon="mat:search"></mat-icon>
                <input [formControl]="searchCtrl"
                       class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-foreground flex-auto"
                       placeholder="Wyszukaj..."
                       type="text"/>
            </div>
        </div>
    </div>

    <div class="-mt-14 pt-0 overflow-hidden flex-1 flex container px-0">

        <mat-drawer-container class="bg-transparent flex-auto flex">
            <mat-drawer [(opened)]="menuOpen" mode="over">
                <app-table-menu (filterChange)="setData($event)"
                                (openAddNew)="createElement.emit()"
                                [items]="tableMenu"
                                class="sm:hidden"></app-table-menu>
            </mat-drawer>
            <mat-drawer-content class="p-gutter pt-0 flex-auto flex items-start">
                <app-table-menu (filterChange)="setData($event)"
                                (openAddNew)="createElement.emit()"
                                [items]="tableMenu"
                                [createLabel]="createLabel"
                                class="hidden sm:block ltr:mr-6 rtl:ml-6"></app-table-menu>

                <div class="card h-full overflow-hidden flex-auto">
                    <app-data-table [columns]="tableColumns"
                                    (openElement)="openElement.emit($event)"
                                    [data]="tableData"
                                    [rowMenu]="rowMenu"
                                    [category]="category"
                                    [searchStr]="searchStr$ | async"></app-data-table>
                </div>
            </mat-drawer-content>

        </mat-drawer-container>
    </div>
</div>

