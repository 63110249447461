import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableMenu} from "../../component/table/model/table-menu";
import {TableColumn} from "../../../@vex/interfaces/table-column.interface";
import {Subscription} from "rxjs";
import {SnackBarService} from "../../service/snack-bar.service";
import {ErrorResponse} from "../../model/error-response";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserEditComponent} from "../user-edit/user-edit.component";
import {ConfirmComponent} from "../../component/dialog/confirm/confirm.component";
import {MatMenu} from "@angular/material/menu";
import {IUser} from "../model/user.model";
import {UserService} from "../service/user.service";
import {ForgotPasswordRequest} from "../../auth/model/forgot-password-request";
import {AuthUserService} from "../../auth/service/auth-user.service";

@Component({
    selector: 'vex-users-list',
    templateUrl: './users-list.component.html'
})
export class UsersListComponent implements OnInit, OnDestroy {
    @ViewChild(MatMenu, {static: true}) rowMenu: MatMenu;
    subscriptionManager = new Subscription();
    editDialogRef: MatDialogRef<UserEditComponent>;
    confirmDialogRef: MatDialogRef<ConfirmComponent>;
    category: string;
    tableMenu: TableMenu[] = [
        {
            type: 'link',
            id: 'default',
            icon: 'mat:view_headline',
            label: 'Aktywne'
        },
        {
            type: 'link',
            id: 'archived',
            icon: 'mat:view_headline',
            label: 'Archiwalne'
        },
        {
            type: 'link',
            id: 'all',
            icon: 'mat:view_headline',
            label: 'Wszystkie'
        },
    ];

    tableColumns: TableColumn<IUser>[] = [
        {
            label: '',
            property: 'selected',
            type: 'checkbox',
            cssClasses: ['w-6']
        },
        {
            label: 'EMAIL',
            property: 'username',
            type: 'text',
            cssClasses: ['font-secondary']
        },
        {
            label: 'IMIĘ',
            property: 'firstName',
            type: 'text',
            cssClasses: ['text-secondary']
        },
        {
            label: 'NAZWISKO',
            property: 'lastName',
            type: 'text',
            cssClasses: ['text-secondary']
        },
        {
            label: 'TELEFON',
            property: 'phone',
            type: 'text',
            cssClasses: ['text-secondary']
        },
        {
            label: 'Aktywny',
            property: 'enabled',
            type: 'boolean'
        },
        {
            label: 'Archiwalny',
            property: 'archived',
            type: 'boolean',
            category: ['all']
        },
        {
            label: '',
            property: 'menu',
            type: 'button',
            cssClasses: ['text-secondary', 'w-10']
        },
    ];

    title = "Administratorzy";

    data: IUser[] = [];


    constructor(
        private userService: UserService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private authUserService: AuthUserService,
    ) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData(category: string = 'default') {
        this.category = category
        const queryObject: any = {
            page: 0,
            size: 10,
            eagerload: true,
            sort: ['id,desc'],
            category: category
        };

        this.userService.paginatedAdminList(queryObject).subscribe({
            next: (users: IUser[]) => {
                this.data = users;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionManager.unsubscribe();
    }

    edit(id?: number) {
        this.editDialogRef = this.dialog.open(UserEditComponent, {
            data: id || null,
            width: '600px'
        });

        this.subscriptionManager.add(
            this.editDialogRef.afterClosed().subscribe((success: string) => {
                if (success) {
                    this.loadData(this.category);
                }
            })
        )
        ;
    }

    archive(id?: number) {
        this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
            data: {title: "Przenieś do archiwum", message: "<p>Czy napewno chcesz przenieść administratora do archiwum?</p>"},
            width: '600px'
        });

        this.subscriptionManager.add(
            this.confirmDialogRef.afterClosed().subscribe((success: string) => {
                if (success) {
                    this.archiveUser(id);
                }
            })
        );
    }

    archiveUser(id?: number) {
        this.userService.archiveUser({id: id}).subscribe({
            next: () => {
                this.snackBarService.show("Administrator zarchiwizowany");
                this.loadData(this.category);
            },
            error: (data: ErrorResponse) => {
                this.snackBarService.show(data.error.message);
            }
        });
    }

    restore(id?: number) {
        this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
            data: {title: "Przywróć administratora", message: "<p>Czy napewno chcesz przywrócić administratora?</p>"},
            width: '600px'
        });

        this.subscriptionManager.add(
            this.confirmDialogRef.afterClosed().subscribe((success: string) => {
                if (success) {
                    this.restoreUser(id);
                }
            })
        );
    }

    restoreUser(id?: number) {
        this.userService.restoreUser({id: id}).subscribe({
            next: () => {
                this.snackBarService.show("Administrator przywrócony");
                this.loadData(this.category);
            },
            error: (data: ErrorResponse) => {
                this.snackBarService.show(data.error.message);
            }
        });
    }

    toggleActivityStatus(user: IUser) {
        this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: user.enabled ? "Dezaktywacja administratora" : "Aktywacja administratora",
                message: "<p>Czy napewno chcesz " + (user.enabled ? "dezaktywować" : "aktywować") + " administratora?</p>"
            },
            width: '600px'
        });

        this.subscriptionManager.add(
            this.confirmDialogRef.afterClosed().subscribe((success: string) => {
                if (success) {
                    this.toggleUserActivityStatus(user);
                }
            })
        );
    }

    toggleUserActivityStatus(user: IUser) {
      this.userService.patchUser({id: user.id, enabled: !user.enabled}).subscribe({
        next: (data: IUser) => {
          this.snackBarService.show("Dane zostały zapisane");
          this.loadData(this.category);
        },
        error: (data: ErrorResponse) => {
          this.snackBarService.show(data.error.message);
        }
      });
    }

    resetPassword(username: string) {
        this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
            data: {title: "Resetuj hasło", message: "<p>Czy napewno chcesz zresetować hasło?</p>"},
            width: '400px'
        });

        this.confirmDialogRef.afterClosed().subscribe((success: string) => {
            if (success) {
                this.sendForgotPasswordEmail(username)
            }
        });
    }

    sendForgotPasswordEmail(username: string) {
        const requestData = new ForgotPasswordRequest();
        requestData.email = username;

        this.authUserService.forgotPassword(requestData).subscribe({
            next: () => {
                this.snackBarService.show("Wysłano email z linkiem do zmiany hasła");
            },
            error: (data: ErrorResponse) => {
                this.snackBarService.show(data.error.message);
            }
        });
    }
}
