import {Injectable} from '@angular/core';
import {LoginUserResponse} from "../model/login-user-response";
import {Router} from "@angular/router";
import {ErrorResponse} from "../../model/error-response";
import {LoginUserRequest} from "../model/login-user-request";
import {SnackBarService} from "../../service/snack-bar.service";
import {TokenStorageService, TokenType} from "../../service/token-storage.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthUserService} from "./auth-user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
      private tokenStorage: TokenStorageService,
      private router: Router,
      private authUserService: AuthUserService,
      private snackBarService: SnackBarService,
      private jwtHeplerService: JwtHelperService
  ) { }

  login(requestData: LoginUserRequest) {
    this.authUserService.login(requestData).subscribe({
      next: (data: LoginUserResponse) => {
        this.tokenStorage.setToken(TokenType.AccessToken, data.accessToken);
        this.tokenStorage.setToken(TokenType.RefreshToken, data.refreshToken);
        this.redirectToDashboard();
      },
      error: (data: ErrorResponse) => {
        this.snackBarService.show(data.error.message);
      }
    });
  }

  logout() {
    this.authUserService.logout().subscribe({
      next: () => {
        this.tokenStorage.removeToken(TokenType.AccessToken);
        this.tokenStorage.removeToken(TokenType.RefreshToken);
        this.redirectToLoginPage();
      },
      error: (data: ErrorResponse) => {
        this.snackBarService.show(data.error.message);
      }
    });
  }

  refresh() {
    return this.authUserService.refreshToken();
  }

  isAuthenticated() : boolean {
    const token = this.tokenStorage.getToken(TokenType.AccessToken);

    if (token === null) {
      return false;
    }

    return !this.jwtHeplerService.isTokenExpired(token);
  }

  isTokenExpired(token: string) {
    return this.jwtHeplerService.isTokenExpired(token, 10);
  }

  hasRole(role: string): boolean {
    const token = this.tokenStorage.getToken(TokenType.AccessToken);

    if (token === null) {
      return false;
    }

    const decodedToken = this.jwtHeplerService.decodeToken(token);

    return decodedToken['roles'][0] === role;
  }

  getRole() {
    const token = this.tokenStorage.getToken(TokenType.AccessToken);

    if (token === null) {
      return null;
    }

    const decodedToken = this.jwtHeplerService.decodeToken(token);

    return decodedToken['roles'][0];
  }

  getUserFullName() {
    const token = this.tokenStorage.getToken(TokenType.AccessToken);

    if (token === null) {
      return null;
    }

    const decodedToken = this.jwtHeplerService.decodeToken(token);

    return decodedToken['fullName'];
  }

  redirectToLoginPage() : void {
    this.router.navigate(['/login']);
  }

  redirectToDashboard() : void {
    this.router.navigate(['/']);
  }
}
