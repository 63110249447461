import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {SnackBarService} from "../../service/snack-bar.service";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private snackBarService: SnackBarService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        this.snackBarService.show(err.error?.detail)
                    }
                },
            })
        );
    }
}
