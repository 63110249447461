import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {stagger40ms} from "../../../@vex/animations/stagger.animation";
import {scaleIn400ms} from "../../../@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "../../../@vex/animations/fade-in-right.animation";
import {ReactiveFormsModule, UntypedFormControl} from "@angular/forms";
import {debounceTime} from "rxjs";
import {TableColumn} from "../../../@vex/interfaces/table-column.interface";
import {TableMenu} from "./model/table-menu";
import {MatIconModule} from "@angular/material/icon";
import {MatSidenavModule} from "@angular/material/sidenav";
import {TableMenuComponent} from "./table-menu/table-menu.component";
import {DataTableComponent} from "./data-table/data-table.component";
import {AsyncPipe} from "@angular/common";
import {MatMenu} from "@angular/material/menu";
import {currencyCodes} from "../../consts/currency.const";

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    MatSidenavModule,
    TableMenuComponent,
    DataTableComponent,
    AsyncPipe,
  ],
  selector: 'app-table',
  templateUrl: './table.component.html',
  animations: [
    stagger40ms,
    scaleIn400ms,
    fadeInRight400ms
  ],
  styles: [`
    .mat-drawer-container {
      background: transparent !important;
    }
  `]
})
export class TableComponent<T> implements OnInit {
  searchCtrl = new UntypedFormControl();
  category: string = 'default';

  searchStr$ = this.searchCtrl.valueChanges.pipe(
      debounceTime(10)
  );

  menuOpen = false;

  @Input() tableColumns: TableColumn<T>[];
  @Input() tableMenu: TableMenu[];
  @Input() rowMenu: MatMenu;
  @Input() title: string;
  @Input() createLabel: string;
  @Input() tableData: T[] = [];

  @Output() createElement = new EventEmitter<void>();
  @Output() openElement = new EventEmitter<number>();
  @Output() setTableData = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  openMenu() {
    this.menuOpen = true;
  }

  setData(category: string) {
    this.category = category;
    this.setTableData.emit(category);
    this.menuOpen = false;
  }

  protected readonly currencyCodes = currencyCodes;
}
