import {NgModule} from '@angular/core';
import {VexModule} from '../@vex/vex.module';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRippleModule} from "@angular/material/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ScrollbarModule} from "../@vex/components/scrollbar/scrollbar.module";
import {MatTableModule} from "@angular/material/table";
import {MatMenuModule} from "@angular/material/menu";
import {MatSortModule} from "@angular/material/sort";
import {MatDividerModule} from "@angular/material/divider";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatCardModule} from "@angular/material/card";

@NgModule({
    imports: [
        // Vex
        VexModule,
        CustomLayoutModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatRippleModule,
        MatPaginatorModule,
        ScrollbarModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatCardModule
    ],
    exports: [
        // Vex
        VexModule,
        CustomLayoutModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatRippleModule,
        MatPaginatorModule,
        ScrollbarModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatCardModule
    ]
})
export class AppVexModule {
}
