import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fadeInRight400ms} from "../../../../@vex/animations/fade-in-right.animation";
import {stagger40ms} from "../../../../@vex/animations/stagger.animation";
import {TableMenu} from "../model/table-menu";
import {MatIconModule} from "@angular/material/icon";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {AppVexModule} from "../../../vex.module";

@Component({
  standalone: true,
    imports: [MatIconModule, NgClass, NgFor, NgIf, AppVexModule],
  selector: 'app-table-menu',
  templateUrl: './table-menu.component.html',
  animations: [fadeInRight400ms, stagger40ms]
})
export class TableMenuComponent implements OnInit {

  @Input() items: TableMenu[];
  @Input() createLabel: string;

  @Output() filterChange = new EventEmitter<string>();
  @Output() openAddNew = new EventEmitter<void>();

  activeCategory: TableMenu['id'] = 'default';

  constructor() { }

  ngOnInit() {
  }

  setFilter(category: TableMenu['id']) {
    this.activeCategory = category;
    this.filterChange.emit(category);
  }

  isActive(category: TableMenu['id']) {
    return this.activeCategory === category;
  }
}
