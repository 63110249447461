import {Injectable} from "@angular/core";
import {ApiService} from "../../service/api.service";
import {Client} from "../model/client";
import {RegisterClientRequest} from "../../auth/model/register-client-request";
import {Observable} from "rxjs";
import {IClient} from "../model/client.model";

@Injectable({providedIn: 'root'})
export class AdminClientApiService extends ApiService<Client> {
  private readonly clientsUrl = '/admin/clients';
  private readonly accountingOfficeClientsUrl = '/admin/clients/accounting';

  updateClient(clientId: number, data: IClient) {
    return this.update(this.clientsUrl, clientId, data);
  }

  addClient(data: RegisterClientRequest) {
    return this.post(this.clientsUrl, data);
  }

  getClient(clientId: number) {
    return this.get(this.clientsUrl + "/" + clientId);
  }

  public paginatedList(params?: any): Observable<Client[]> {
    return this.getPaginated(this.accountingOfficeClientsUrl, params);
  }

  addAccountingOfficeClient(data: RegisterClientRequest) {
    return this.post(this.accountingOfficeClientsUrl, data);
  }

  public archiveClient(client: IClient) {
    return this.archive(this.clientsUrl, client.id, client);
  }

  public restoreClient(client: IClient) {
    return this.restore(this.clientsUrl, client.id, client);
  }
}