import { CanActivateFn } from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../auth/service/auth.service";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    authService.redirectToLoginPage();
    return false;
  }

  return true;
};
