import {Component, OnInit} from '@angular/core';
import {AuthService} from "../service/auth.service";

@Component({
  selector: 'vex-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(
      private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.authService.logout();
  }


}
