import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {format, fromZonedTime} from "date-fns-tz";

@Injectable()
export class DateFormatInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/api/v1/')) {
      this.formatObjectDates(request.body as object);
    }

    return next.handle(request);
  }

  formatObjectDates(bodyObject: object) {
    for (const property in bodyObject) {
      if (bodyObject[property] instanceof Date) {
        bodyObject[property] = format(bodyObject[property], "yyyy-MM-dd'T'HH:mm:ss");
      } else if (typeof bodyObject[property] == 'object') {
        this.formatObjectDates(bodyObject[property]);
      }
    }
  }
}
