import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {AuthService} from "../../auth/service/auth.service";



@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (err: HttpErrorResponse) => {
                    if (err.status === 401 && err.url && !err.url.includes('api/v1/auth/login')) {
                        this.authService.logout();
                    }
                },
            }),
        );
    }
}
