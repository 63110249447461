import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoginUserRequest} from "../model/login-user-request";
import {AuthService} from "../service/auth.service";

@Component({
    selector: 'vex-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

    form: UntypedFormGroup;

    inputType = 'password';
    visible = false;
    isSubmitted = false;

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.form = this.fb.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }
        this.isSubmitted = true;

        const formValue = this.form.getRawValue();
        const requestData = new LoginUserRequest(formValue);

        this.authService.login(requestData);

        this.isSubmitted = false;
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }
}