import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, throwError} from 'rxjs';
import {TokenStorageService, TokenType} from "../service/token-storage.service";
import {AuthService} from "../auth/service/auth.service";
import {LoginUserResponse} from "../auth/model/login-user-response";
import {filter, switchMap, take} from "rxjs/operators";
import {whitelistEndpoints} from "../consts/whitelist-endpoints.const";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private refreshingInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private tokenStorage: TokenStorageService,
        private authService: AuthService
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!request.url.includes(environment.apiUrl)) {
            return next.handle(request);
        }

        const accessToken = this.tokenStorage.getToken(TokenType.AccessToken);
        const refreshToken = this.tokenStorage.getToken(TokenType.RefreshToken);

        if (!accessToken || whitelistEndpoints.some((e) => request.url.includes(e))) {
            return next.handle(request);
        }

        if (request.url.includes('/api/v1/auth/token/refresh')) {
            return next.handle(
                request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + refreshToken),
                })
            );
        }

        if (this.authService.isTokenExpired(accessToken)) {
            if (!this.refreshingInProgress) {
                this.refreshingInProgress = true;
                this.refreshTokenSubject.next(null);


                return this.authService.refresh().pipe(
                    switchMap((res: LoginUserResponse) => {
                        this.tokenStorage.setToken(TokenType.AccessToken, res.accessToken);
                        this.tokenStorage.setToken(TokenType.RefreshToken, res.refreshToken);
                        this.refreshTokenSubject.next(res.accessToken);
                        this.refreshingInProgress = false;

                        return this.retryRequests(request, next);
                    }),
                    catchError((err) => {
                        this.authService.logout();
                        this.refreshingInProgress = false;
                        return throwError(err);
                    })
                );

            } else {
                return this.retryRequests(request, next);
            }
        }

        const requestClone = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
        });

        return next.handle(requestClone);
    }

    private retryRequests(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => {
                const requestClone = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token),
                });

                return next.handle(requestClone);
            })
        );
    }
}
