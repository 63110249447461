import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';

import {TokenStorageService, TokenType} from "../../service/token-storage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private tokenStorage: TokenStorageService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url || (request.url.startsWith('http') && request.url.includes('api/v1/auth/login'))
            || request.url.startsWith('assets/')) {
            return next.handle(request);
        }

        const token: string | null = this.tokenStorage.getToken(TokenType.AccessToken);
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(request);
    }
}
