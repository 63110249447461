<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
    <div class="card overflow-hidden w-full max-w-md">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
            <div class="text-center content-center">
                <img class="w-72" src="../../../assets/logo.png">
            </div>
        </div>

        <div class="text-center mt-4">
            <h2 class="title m-0">Witamy ponownie!</h2>
            <h4 class="body-2 text-secondary m-0">Zaloguj się, aby skorzystać z aplikacji</h4>
        </div>

        <div [formGroup]="form" class="p-6 flex flex-col gap-4">
            <div class="flex flex-col">
                <mat-form-field class="flex-1">
                    <mat-label>Adres email</mat-label>
                    <input formControlName="username" matInput required>
                    <mat-error *ngIf="form.get('username').hasError('required')">Adres email jest wymagany</mat-error>
                    <mat-error *ngIf="form.get('username').hasError('email')">Adres email jest niepoprawny</mat-error>
                </mat-form-field>
                <mat-form-field class="flex-1">
                    <mat-label>Hasło</mat-label>
                    <input [type]="inputType" formControlName="password" matInput required>
                    <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
                        <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                        <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('required')">Hasło jest wymagane</mat-error>
                </mat-form-field>
            </div>

            <div class="flex items-center justify-between">
                <a [routerLink]="['/forgot-password']" class="caption">Zapomniałeś hasła?</a>
            </div>

            <button [disabled]="isSubmitted" (click)="submit()" color="primary" mat-raised-button type="button">
                ZALOGUJ SIĘ
            </button>

        </div>
    </div>
</div>
