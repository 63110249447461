import {BrowserModule} from '@angular/platform-browser';
import {importProvidersFrom, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './auth/login/login.component';
import {RegisterClientComponent} from './auth/register-client/register-client.component';
import {LogoutComponent} from './auth/logout/logout.component';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {UsersListComponent} from './user/users-list/users-list.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TableComponent} from './component/table/table.component';
import {UserVerificationComponent} from './auth/user-verification/user-verification.component';
import {ConfirmComponent} from './component/dialog/confirm/confirm.component';
import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {AuthenticationInterceptor} from "./interceptor/authentication.interceptor";
import {AppVexModule} from "./vex.module";
import {AuthInterceptor} from "./config/interceptor/auth.interceptor";
import {AuthExpiredInterceptor} from "./config/interceptor/auth-expired.interceptor";
import {MAIN_LOCALE} from "./app.constants";
import {ErrorHandlerInterceptor} from "./config/interceptor/error-handler.interceptor";
import {registerLocaleData} from "@angular/common";
import localePl from '@angular/common/locales/pl';
import {DateFormatInterceptor} from "./interceptor/date-format.interceptor";

registerLocaleData(localePl, MAIN_LOCALE);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterClientComponent,
        LogoutComponent,
        NotFoundComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        UsersListComponent,
        DashboardComponent,
        UserVerificationComponent,
        ConfirmComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppVexModule,
        TableComponent,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'pl-PL'},
        importProvidersFrom(
            JwtModule.forRoot({
                config: {
                    tokenGetter: function tokenGetter() {
                        return localStorage.getItem('access-token');
                    }
                },
            })
        ),
        {provide: JwtHelperService},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateFormatInterceptor,
            multi: true,
        },
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
