<div class="bg-pattern w-full h-full flex flex-col items-center justify-center">
    <div [@fadeInUp] class="card overflow-hidden w-full max-w-md">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
            <div class="text-center content-center">
                <img class="w-72" src="../../../assets/logo.png">
            </div>
        </div>

        <div *ngIf="!confirm" class="text-center mt-4">
            <h2 class="title m-0">Zresetuj hasło</h2>
            <h4 class="body-2 text-secondary m-0">Wpisz swój adres e-mail w celu odzyskania hasła.</h4>
        </div>

        <div *ngIf="!confirm" [formGroup]="form" class="p-6 flex flex-col">
            <mat-form-field>
                <mat-label>E-Mail</mat-label>

                <mat-icon class="mr-2" matPrefix svgIcon="mat:mail"></mat-icon>
                <input formControlName="email" matInput required>
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Adres email jest wymagany
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('email')">
                    Adres email jest niepoprawny
                </mat-error>
            </mat-form-field>

            <div class="flex flex-row gap-2 mt-2">
            <button class="basis-1/2" [disabled]="isSubmitted" routerLink="/login" mat-raised-button type="button">
                COFNIJ
            </button>
            <button class="basis-1/2" [disabled]="isSubmitted" (click)="send()" color="primary" mat-raised-button type="button">
                WYŚLIJ LINK
            </button>
            </div>
        </div>

        <div *ngIf="confirm" class="p-6 flex flex-col">
            <div class="text-center mt-4">
                <h4 class="body-2 text-secondary m-0">Na podany adres email został wysłany link aktywujący zmianę
                    hasła</h4>
                <h4 class="body-2 text-secondary m-0">Link ważny 24 godziny.</h4>
            </div>
            <button [routerLink]="['/login']" class="mt-8" color="primary" mat-raised-button type="button">
                ZALOGUJ
            </button>
        </div>
    </div>
</div>
