export class RegisterClientRequest {
    name: string;
    domain: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    nip: string;
    regulationAcceptance: boolean;
    accountingOffice: boolean;

    public constructor(init?: Partial<RegisterClientRequest>) {
        Object.assign(this, init);
    }
}
