import {MenuItem} from "../../@vex/layout/toolbar/toolbar-user/interfaces/menu-item.interface";
import {
    OnlineStatus
} from "../../@vex/layout/toolbar/toolbar-user/toolbar-user-dropdown/toolbar-user-dropdown.component";

export const toolbarElements: MenuItem[] = [
    {
        id: '1',
        icon: 'mat:account_circle',
        label: 'Mój profil',
        description: 'Informacje osobiste',
        colorClass: 'text-teal',
        route: '/my-profile'
    }
];

export const toolbarStatuses: OnlineStatus[] = [
    {
        id: 'online',
        label: 'Online',
        icon: 'mat:check_circle',
        colorClass: 'text-green'
    },
    {
        id: 'away',
        label: 'Away',
        icon: 'mat:access_time',
        colorClass: 'text-orange'
    },
    {
        id: 'dnd',
        label: 'Do not disturb',
        icon: 'mat:do_not_disturb',
        colorClass: 'text-red'
    },
    {
        id: 'offline',
        label: 'Offline',
        icon: 'mat:offline_bolt',
        colorClass: 'text-gray'
    }
];