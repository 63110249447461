import { Injectable } from '@angular/core';
import {Role} from "../../model/role";
import {ApiService} from "../../service/api.service";

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiService<Role> {
  private readonly allRolesUrl = '/roles';
  private readonly allRolesForEmployeeUrl = '/roles/employee';

  allRoles() {
    return this.getAll(this.allRolesUrl);
  }

  allRolesForEmployee() {
    return this.getAll(this.allRolesForEmployeeUrl);
  }

  compareRole(o1: Role | null, o2: Role | null): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}