import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SnackBarService} from "../../service/snack-bar.service";
import {ErrorResponse} from "../../model/error-response";
import {Subscription} from "rxjs";
import {Role} from "../../model/role";
import {ConfirmComponent} from "../../component/dialog/confirm/confirm.component";
import {RoleService} from "../service/role.service";
import SharedModule from "../../shared/shared.module";
import {UserFormService} from "../service/user-form.service";
import {UserService} from "../service/user.service";
import {IUser} from "../model/user.model";
import {TableComponent} from "../../component/table/table.component";
import {MatIconModule} from "@angular/material/icon";
import {AppVexModule} from "../../vex.module";

@Component({
    standalone: true,
    selector: 'user-edit',
    imports: [
        SharedModule,
        AppVexModule,
        TableComponent,
        MatIconModule
    ],
    templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit, OnDestroy {
    isSubmitted = false;
    form = this.userFormService.createUserFormGroup();
    confirmDialogRef: MatDialogRef<ConfirmComponent>;

    user: IUser | null = null;
    roles: Role[] = [];
    subscriptionManager = new Subscription();


    constructor(
        @Inject(MAT_DIALOG_DATA) protected userId: IUser['id'],
        private dialogRef: MatDialogRef<UserEditComponent>,
        private userService: UserService,
        private roleService: RoleService,
        private snackBarService: SnackBarService,
        private userFormService: UserFormService
    ) {
    }

    get isEdit(): boolean {
        return !!this.userId;
    }

    ngOnInit() {
        if (this.userId) {
            this.userService.getUser(this.userId)
                .subscribe({
                    next: (user: IUser) => {
                        this.user = user;
                        if (user) {
                            this.userFormService.resetForm(this.form, user);
                        }
                    }
                });
        }

        this.loadRoles();
    }

    ngOnDestroy(): void {
        this.subscriptionManager.unsubscribe();
    }

    save() {
        if (this.form.invalid) {
            return this.form.markAllAsTouched();
        }

        this.isSubmitted = true;

        const user = this.userFormService.getUser(this.form);

        if (user.id) {
            this.userService.updateUser(user).subscribe({
                next: (data: IUser) => {
                    this.snackBarService.show("Dane zostały zapisane");
                    this.close(true);
                },
                error: (data: ErrorResponse) => {
                    this.isSubmitted = false;
                    this.snackBarService.show(data.error.message);
                }
            });
        } else {
            this.userService.addUser(user).subscribe({
                next: (data: IUser) => {
                    this.snackBarService.show("Dane zostały zapisane. Wysłano maila aktywacyjnego");
                    this.close(true);
                },
                error: (data: ErrorResponse) => {
                    this.isSubmitted = false;
                    this.snackBarService.show(data.error.message);
                }
            });
        }
    }

    close(success: boolean) {
        this.dialogRef.close(success);
    }

    loadRoles() {
        return this.roleService.allRoles().subscribe({
            next: (roles: Role[]) => {
                this.roles = roles;
            },
            error: (data: ErrorResponse) => {
                this.snackBarService.show(data.error.message);
            }
        });
    }

    isArchived() {
        return this.user && this.user.archived;
    }
}
