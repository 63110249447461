import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService<T> {
    apiUrl: string;

    protected constructor(
        protected http: HttpClient,
    ) {
        this.apiUrl = environment.apiUrl;
    }

    public get(url: string): Observable<T> {
        return this.http
            .get(this.apiUrl + url)
            .pipe(map((res: T) => res as T));
    }

    public getPaginated(url: string, params: any): Observable<T[]> {
        return this.http
            .get(this.apiUrl + url, {params: params})
            .pipe(map((res: T[]) => res as T[]));
    }

    public post(url: string, data: Object): Observable<T> {
        return this.http
            .post(this.apiUrl + url, data)
            .pipe(map((res: T) => res as T));
    }

    public postMultipart(url: string, data: FormData): Observable<T> {
        return this.http
            .post(this.apiUrl + url, data)
            .pipe(map((res: T) => res as T));
    }

    public put(url: string, data: Object): Observable<T> {
        return this.http
            .put(this.apiUrl + url, data)
            .pipe(map((res: T) => res as T));
    }

    public patch(url: string, data: Object): Observable<T> {
        return this.http
            .patch(this.apiUrl + url, data)
            .pipe(map((res: T) => res as T));
    }

    public delete(url: string): Observable<T> {
        return this.http
            .delete(this.apiUrl + url)
            .pipe(map((res: T) => res as T));
    }

    public getAll(url: string): Observable<T[]> {
        return this.http
            .get(this.apiUrl + url)
            .pipe(map((res: T[]) => res as T[]));
    }

    public getAllById(url: string, id: number): Observable<T[]> {
        return this.http
            .get(this.apiUrl + `${url}/${id}`)
            .pipe(map((res: T[]) => res as T[]));
    }

    public getById(url: string, id: number): Observable<T> {
        return this.get(`${url}/${id}`);
    }

    public update(url: string, id: number, data: Object): Observable<T> {
        return this.put(`${url}/${id}`, data);
    }

    public updateMultipart(url: string, id: number, data: FormData): Observable<T> {
        return this.post(`${url}/${id}`, data);
    }

    public partialUpdate(url: string, id: number, data: Object): Observable<T> {
        return this.patch(`${url}/${id}`, data);
    }

    public deleteById(url: string, id: number): Observable<T> {
        return this.delete(`${url}/${id}`);
    }

    public archive(url: string, id: number, data: Object): Observable<T> {
        return this.patch(`${url}/${id}/archive`, data);
    }

    public restore(url: string, id: number, data: Object): Observable<T> {
        return this.patch(`${url}/${id}/restore`, data);
    }
}
