import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private snackbar: MatSnackBar) { }

  public show(message: string) {
    this.snackbar.open(message, 'Anuluj', {
      duration: 6000,
    });
  }
}
