import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {CustomValidator} from "../../validators/custom-validator";
import {ErrorResponse} from "../../model/error-response";
import {ResetPasswordRequest} from "../model/reset-password-request";
import {SnackBarService} from "../../service/snack-bar.service";
import {ActivatedRoute} from "@angular/router";
import {ValidateTokenRequest} from "../model/validate-token-request";
import {ValidateTokenResponse} from "../model/validate-token-response";
import {fadeInUp400ms} from "../../../@vex/animations/fade-in-up.animation";
import {AuthUserService} from "../service/auth-user.service";

@Component({
    selector: 'vex-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    animations: [fadeInUp400ms]
})
export class ResetPasswordComponent implements OnInit {
    confirm = false;
    inputType = 'password';
    visible = false;
    token: string;
    isValid: boolean;
    isSubmitted = false;

    form = this.fb.group({
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                CustomValidator.upperCase,
                CustomValidator.lowerCase,
                CustomValidator.hasDigit,
                CustomValidator.special
            ]],
            passwordConfirm: ['', Validators.required]
        },
        {
            validators: [CustomValidator.identicalField('password', 'passwordConfirm')]
        });

    constructor(
        private cd: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private authUserService: AuthUserService,
        private snackBarService: SnackBarService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                    this.token = params.token;

                    const validateTokenRequest = new ValidateTokenRequest();
                    validateTokenRequest.token = this.token;

                this.authUserService.validateToken(validateTokenRequest).subscribe({
                    next: (response: ValidateTokenResponse) => {
                        this.isValid = response.isValid;
                    },
                    error: (data: ErrorResponse) => {
                        this.snackBarService.show(data.error.message);
                    }
                });
                }
            );
    }


    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    send() {
        if (!this.form.valid) {
            return false;
        }

        this.isSubmitted = true;

        const formData = this.form.getRawValue();
        const requestData = new ResetPasswordRequest();
        requestData.password = formData.password;
        requestData.token = this.token;

        this.authUserService.resetPassword(requestData).subscribe({
            next: () => {
                this.confirm = true;
            },
            error: (data: ErrorResponse) => {
                this.isSubmitted = false;
                this.snackBarService.show(data.error.message);
            }
        });
    }
}
