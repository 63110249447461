import {ICurrencyCode} from "../shared/model/currency.model";

export const currencyCodes: ICurrencyCode[] = [
    {
        "name": "Polski Złoty",
        "code": "PLN",
    },
    {
        "name": "Bat (Tajlandia)",
        "code": "THB",
    },
    {
        "name": "Dolar Amerykański",
        "code": "USD",
    },
    {
        "name": "Dolar Australijski",
        "code": "AUD",
    },
    {
        "name": "Dolar Hongkongu",
        "code": "HKD",
    },
    {
        "name": "Dolar Kanadyjski",
        "code": "CAD",
    },
    {
        "name": "Dolar Nowozelandzki",
        "code": "NZD",
    },
    {
        "name": "Dolar Singapurski",
        "code": "SGD",
    },
    {
        "name": "Euro",
        "code": "EUR",
    },
    {
        "name": "Forint (Węgry)",
        "code": "HUF",
    },
    {
        "name": "Frank Szwajcarski",
        "code": "CHF",
    },
    {
        "name": "Funt Szterling",
        "code": "GBP",
    },
    {
        "name": "Hrywna (Ukraina)",
        "code": "UAH",
    },
    {
        "name": "Jen (Japonia)",
        "code": "JPY",
    },
    {
        "name": "Korona Czeska",
        "code": "CZK",
    },
    {
        "name": "Korona Duńska",
        "code": "DKK",
    },
    {
        "name": "Korona Islandzka",
        "code": "ISK",
    },
    {
        "name": "Korona Norweska",
        "code": "NOK",
    },
    {
        "name": "Korona Szwedzka",
        "code": "SEK",
    },
    {
        "name": "Lej Rumuński",
        "code": "RON",
    },
    {
        "name": "Lew (Bułgaria)",
        "code": "BGN",
    },
    {
        "name": "Lira Turecka",
        "code": "TRY",
    },
    {
        "name": "Nowy Izraelski Szekel",
        "code": "ILS",
    },
    {
        "name": "Peso Chilijskie",
        "code": "CLP",
    },
    {
        "name": "Peso Filipińskie",
        "code": "PHP",
    },
    {
        "name": "Peso Meksykańskie",
        "code": "MXN",
    },
    {
        "name": "Rand (Republika Południowej Afryki)",
        "code": "ZAR",
    },
    {
        "name": "Real (Brazylia)",
        "code": "BRL",
    },
    {
        "name": "Ringgit (Malezja)",
        "code": "MYR",
    },
    {
        "name": "Rupia Indonezyjska",
        "code": "IDR",
    },
    {
        "name": "Rupia Indyjska",
        "code": "INR",
    },
    {
        "name": "Won Południowokoreański",
        "code": "KRW",
    },
    {
        "name": "Yuan Renminbi (Chiny)",
        "code": "CNY",
    },
    {
        "name": "SDR (MFW)",
        "code": "XDR",
    }
]