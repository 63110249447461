export const ACCEPT_ALL =
    'image/jpg,image/jpeg,image/png,image/gif,.doc,.docx,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/pdf,data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,UEsDBB,data:application/msword;base64,0M8R4KGxGuv';

export const FILES_UPLOAD_LIMIT = 1;
export const FILE_SIZE = 10000000;
export const UPLOAD_FILE_WRONG_FORMAT = 'Zły format pliku';
export const UPLOAD_FILE_FILE_TO_BIG = 'Plik jest za duży. 10MB max.';
export const UPLOAD_FILE_LIMIT = 'Można dodać tylko 1 plik';

export const MAIN_LOCALE = 'pl-PL';
export const CURRENCY = 'zł';
