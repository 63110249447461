import { Component } from '@angular/core';
import {fadeInUp400ms} from "../../../@vex/animations/fade-in-up.animation";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ForgotPasswordRequest} from "../model/forgot-password-request";
import {ErrorResponse} from "../../model/error-response";
import {SnackBarService} from "../../service/snack-bar.service";
import {AuthUserService} from "../service/auth-user.service";

@Component({
  selector: 'vex-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInUp400ms]
})
export class ForgotPasswordComponent {
  form = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]]
  });
  confirm = false;
  isSubmitted = false;

  constructor(
      private router: Router,
      private formBuilder: UntypedFormBuilder,
      private authUserService: AuthUserService,
      private snackBarService: SnackBarService
  ) { }

  send() {
    if (!this.form.valid) {
      return false;
    }

    this.isSubmitted = true;

    const formData = this.form.getRawValue();
    const requestData = new ForgotPasswordRequest(formData);

    this.authUserService.forgotPassword(requestData).subscribe({
      next: () => {
        this.confirm = true;
      },
      error: (data: ErrorResponse) => {
        this.isSubmitted = false;
        this.snackBarService.show(data.error.message);
      }
    });
  }
}
