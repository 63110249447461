import { Injectable } from '@angular/core';
import {RegisterUserRequest} from "../model/register-user-request";
import {LoginUserRequest} from "../model/login-user-request";
import {ForgotPasswordRequest} from "../model/forgot-password-request";
import {ResetPasswordRequest} from "../model/reset-password-request";
import {ValidateTokenRequest} from "../model/validate-token-request";
import {ApiService} from "../../service/api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthUserService extends ApiService<any> {
  private readonly registerUrl = '/auth/register';
  private readonly loginUrl = '/auth/login';
  private readonly logoutUrl = '/auth/logout';
  private readonly refreshTokenUrl = '/auth/token/refresh';
  private readonly validateTokenUrl = '/auth/token/validate';
  private readonly forgotPasswordUrl = '/auth/password/forgot';
  private readonly resetPasswordUrl = '/auth/password/reset';
  private readonly verificationUrl = '/auth/account/verify';

  register(data: RegisterUserRequest) {
    return this.post(this.registerUrl, data);
  }

  login(data: LoginUserRequest) {
    return this.post(this.loginUrl, data);
  }

  logout() {
    return this.post(this.logoutUrl, {});
  }

  refreshToken() {
    return this.post(this.refreshTokenUrl, {});
  }

  forgotPassword(data: ForgotPasswordRequest) {
    return this.post(this.forgotPasswordUrl, data);
  }

  resetPassword(data: ResetPasswordRequest) {
    return this.post(this.resetPasswordUrl, data);
  }

  verifyAccount(data: ResetPasswordRequest) {
    return this.post(this.verificationUrl, data);
  }

  validateToken(data: ValidateTokenRequest) {
    return this.post(this.validateTokenUrl, data);
  }
}
