import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from "@angular/material/form-field";
import {stagger20ms} from "../../../../@vex/animations/stagger.animation";
import {fadeInUp400ms} from "../../../../@vex/animations/fade-in-up.animation";
import {scaleFadeIn400ms} from "../../../../@vex/animations/scale-fade-in.animation";
import {TableColumn} from "../../../../@vex/interfaces/table-column.interface";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ScrollbarModule} from "../../../../@vex/components/scrollbar/scrollbar.module";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatMenu, MatMenuModule} from "@angular/material/menu";
import {TableRowObjectPipe} from "./table-row-object.pipe";

@Component({
  standalone: true,
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ],
  imports: [
    ScrollbarModule,
    MatTableModule,
    NgClass,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    NgFor,
    NgIf,
    TableRowObjectPipe
  ],
  animations: [
    stagger20ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class DataTableComponent<T> implements OnChanges, AfterViewInit {
  @Input() data: T[];
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 20;
  @Input() pageSizeOptions = [10, 20, 50];
  @Input() searchStr: string;
  @Input() category: string;

  @Input() rowMenu: MatMenu;

  @Output() toggleStar = new EventEmitter<number>();
  @Output() openElement = new EventEmitter<number>();

  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns
          .filter(column => this.enabledForCategory(column))
          .map(column => column.property);
    }

    if (changes.category) {
      this.visibleColumns = this.columns
          .filter(column => this.enabledForCategory(column))
          .map(column => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data;
    }

    if (changes.searchStr) {
      this.dataSource.filter = (this.searchStr || '').trim().toLowerCase();
    }
  }

  emitToggleStar(event: Event, id: number) {
    event.stopPropagation();
    this.toggleStar.emit(id);
  }

  enabledForCategory(column: TableColumn<T>): boolean {
    if (!column.category) {
      return true;
    }

    return column.category.includes(this.category);
  }
}
