import { Injectable } from '@angular/core';

export enum TokenType {
  AccessToken = 'access-token',
  RefreshToken = 'refresh-token',
}

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  getToken(key: TokenType): string | null {
    return localStorage.getItem(key);
  }

  setToken(key: TokenType, token: string): void {
    localStorage.setItem(key, token);
  }

  removeToken(key: TokenType) {
    localStorage.removeItem(key);
  }
}
