<vex-scrollbar class="h-full relative">
    <div class="flex flex-col justify-between">
        <table [@stagger]="dataSource.filteredData"
               [dataSource]="dataSource"
               class="w-full flex-auto"
               mat-table
               matSort>

            <!-- Model Properties Column -->
            <ng-container *ngFor="let column of columns">
                <ng-container *ngIf="column.type === 'text' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                </ng-container>

                <ng-container *ngIf="column.type === 'checkbox' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                        <mat-checkbox (click)="$event.stopPropagation()" [checked]="row[column.property]"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container *ngIf="column.type === 'image' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                        <img [src]="row[column.property]" class="avatar h-9 w-9 align-middle my-2">
                    </td>
                </ng-container>

                <ng-container *ngIf="column.type === 'boolean' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                    <td *matCellDef="let row" mat-cell>
                        <mat-checkbox disabled [checked]="row[column.property]"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container *ngIf="column.type === 'object' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                        {{ row[column.property] | tableRowObject: column.objectProperties }}
                    </td>
                </ng-container>

                <ng-container *ngIf="column.type === 'button' && enabledForCategory(column)" [matColumnDef]="column.property">
                    <ng-container *ngIf="column.property === 'starred'">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-10" mat-cell>
                            <button (click)="emitToggleStar($event, row.id)" mat-icon-button type="button">
                                <mat-icon *ngIf="row[column.property]" class="text-amber" svgIcon="mat:star"></mat-icon>
                                <mat-icon *ngIf="!row[column.property]" svgIcon="mat:star_border"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="column.property === 'menu'">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-10" mat-cell>
                            <button (click)="$event.stopPropagation()"
                                    [matMenuTriggerFor]="rowMenu"
                                    [matMenuTriggerData]="{row: row, category: column.category}"
                                    mat-icon-button
                                    type="button">
                                <mat-icon svgIcon="mat:more_vert"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>


            <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
            <!--suppress UnnecessaryLabelJS -->
            <tr (click)="openElement.emit(row.id)"
                *matRowDef="let row; columns: visibleColumns;"
                @fadeInUp
                class="hover:bg-hover cursor-pointer"
                mat-row></tr>
        </table>

        <div *ngIf="dataSource.filteredData.length === 0"
             @scaleFadeIn
             class="flex-auto flex flex-col items-center justify-center">
            <img class="m-12 h-64" src="assets/img/illustrations/no-data.jpg">
            <h2 class="headline m-5 text-center">Brak rekordów</h2>
        </div>

        <mat-paginator [class.hidden]="dataSource.filteredData.length === 0"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       class="sticky bottom-0 left-0 right-0 border-t flex-none"></mat-paginator>
    </div>
</vex-scrollbar>
