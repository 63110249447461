<div class="w-full h-full bg-pattern flex flex-col items-center overflow-scroll">
    <div class="card w-full max-w-md m-3" [hidden]="formHidden">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
            <div class="text-center content-center">
                <img class="w-72" src="../../../assets/logo.png">
            </div>
        </div>

        <div class="text-center mt-4">
            <h2 class="title m-0">Zarejestruj się</h2>
            <h4 class="body-2 text-secondary m-0">Wypełnij formularz</h4>
        </div>

        <form [formGroup]="form" class="p-6 flex flex-col gap-4 flex-auto flex flex-col" (submit)="submit()">
            <div>
                <mat-form-field class="flex-1 block">
                    <mat-label>Nazwa firmy</mat-label>
                    <input formControlName="name" matInput required>
                    <mat-error *ngIf="form.get('name').hasError('required')">Nazwa firmy jest wymagana
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block">
                    <mat-label>NIP</mat-label>
                    <input formControlName="nip" matInput required>
                    <mat-error *ngIf="form.get('nip').hasError('required')">Numer NIP jest wymagany</mat-error>
                    <mat-error *ngIf="form.get('nip').hasError('isDigit')">Numer NIP musi zawierać wyłącznie cyfry</mat-error>
                    <mat-error *ngIf="form.get('nip').hasError('minlength') || form.get('nip').hasError('maxlength')">
                        Numer NIP musi zawierać 10 cyfr
                    </mat-error>
                </mat-form-field>

                <div class="flex items-left justify-left flex-col mb-5">
                    <div>
                        <mat-checkbox formControlName="accountingOffice" id="isAccountingOffice"/>
                        <label for="isAccountingOffice">Jestem Biurem Rachunkowym</label>
                    </div>
                </div>

                <mat-form-field class="flex-1 block">
                    <mat-label>Subdomena</mat-label>
                    <input formControlName="domain" matInput required>
                    <mat-error *ngIf="form.get('domain').hasError('required')">Nazwa domeny jest wymagana</mat-error>
                    <mat-error *ngIf="form.get('domain').hasError('pattern')">Nazwa domeny powinna zawierać tylko małe
                        litery i cyfry</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block">
                    <mat-label>Adres email</mat-label>
                    <input formControlName="email" matInput required>
                    <mat-error *ngIf="form.get('email').hasError('required')">Adres email jest wymagany</mat-error>
                    <mat-error *ngIf="form.get('email').hasError('email')">Adres email jest niepoprawny</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block">
                    <mat-label>Numer telefonu</mat-label>
                    <input formControlName="phone" matInput required>
                    <mat-error *ngIf="form.get('phone').hasError('required')">Numer telefonu jest wymagany</mat-error>
                    <mat-error *ngIf="form.get('phone').hasError('isDigit')">Numer telefonu musi zawierać wyłącznie cyfry</mat-error>
                    <mat-error *ngIf="form.get('phone').hasError('minlength') || form.get('phone').hasError('maxlength')">
                        Numer telefonu musi zawierać 9 cyfr
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block">
                    <mat-label>Imię</mat-label>
                    <input formControlName="firstName" matInput required>
                    <mat-error *ngIf="form.get('firstName').hasError('required')">Imię jest wymagane</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block">
                    <mat-label>Nazwisko</mat-label>
                    <input formControlName="lastName" matInput required>
                    <mat-error *ngIf="form.get('lastName').hasError('required')">Nazwisko jest wymagane</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-1 block" subscriptSizing="dynamic">
                    <mat-label>Hasło</mat-label>
                    <input [type]="inputType" formControlName="password" matInput required>
                    <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility"
                            type="button">
                        <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                        <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('required')">Hasło jest wymagane</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">Hasło musi zawierać minimum 8
                        znaków
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('upperCase')">Hasło musi zawierać przynajmniej
                        jedną wielką literę
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('lowerCase')">Hasło musi zawierać przynajmniej
                        jedną małą literę
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('hasDigit')">Hasło musi zawierać przynajmniej
                        jedną cyfrę
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('special')">Hasło musi zawierać przynajmniej
                        jeden znak specjalny (!@#$%^&*)
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('containsOtherFieldValue')">Hasło nie może zawierać
                        nazwy domeny
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="flex items-center justify-center flex-col">
                <div>
                    <mat-checkbox formControlName="regulationAcceptance" id="regulationAcceptance"/>
                    <label for="regulationAcceptance">Akceptuję regulamin</label>
                </div>
                <div>
                    <mat-error *ngIf="this.form.touched && form.get('regulationAcceptance').hasError('required')"
                    >Musisz zaakceptować regulamin
                    </mat-error>
                </div>
            </div>
            <button [disabled]="isSubmitted" color="primary" mat-raised-button>
                UTWÓRZ KONTO
            </button>
        </form>
    </div>
    <div class="card w-full max-w-md mt-48" [hidden]="!formHidden">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
            <div class="text-center content-center">
                <img class="w-72" src="../../../assets/logo.png">
            </div>
        </div>
        <div class="text-center m-4">
            <h2 class="title m-0">Dziękujemy!</h2>
            <h4 class="body-2 text-secondary m-5">Rejestracja przebiegła pomyślnie!.</h4>
            <a [href]="loginUrl" mat-raised-button color="primary" class="m-5">ZALOGUJ SIĘ</a>
        </div>
    </div>
</div>
