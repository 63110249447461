<div class="bg-pattern w-full h-full flex flex-col items-center justify-center">
    <div [@fadeInUp] class="card overflow-hidden w-full max-w-md">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
            <div class="text-center content-center">
                <img class="w-72" src="../../../assets/logo.png">
            </div>
        </div>

        <div *ngIf="!isValid" class="p-6 flex flex-col text-center">
            <h2 class="title mb-5">Token stracił ważność</h2>
            <button [routerLink]="['/login']" class="mt-8" color="primary" mat-raised-button type="button">
                WRÓĆ
            </button>
        </div>

        <div *ngIf="!confirm && isValid" class="text-center mt-4">
            <h2 class="title m-0">Aktywuj swoje konto</h2>
            <h4 class="body-2 text-secondary m-0">Utwórz hasło</h4>
        </div>

        <form *ngIf="!confirm && isValid" [formGroup]="form" class="p-6 flex flex-col" (submit)="send()">
            <mat-form-field class="flex-1 block mb-6" subscriptSizing="dynamic">
                <mat-label>Hasło</mat-label>
                <input [type]="inputType" formControlName="password" matInput required>
                <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
                    <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                    <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                </button>
                <mat-error *ngIf="form.get('password').hasError('required')">Hasło jest wymagane</mat-error>
                <mat-error *ngIf="form.get('password').hasError('minlength')">Hasło musi zawierać minimum 8
                    znaków
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('upperCase')">Hasło musi zawierać przynajmniej
                    jedną wielką literę
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('lowerCase')">Hasło musi zawierać przynajmniej
                    jedną małą literę
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('hasDigit')">Hasło musi zawierać przynajmniej
                    jedną cyfrę
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('special')">Hasło musi zawierać przynajmniej
                    jeden znak specjalny (!@#$%^&*)
                </mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1 block">
                <mat-label>Potwierdź hasło</mat-label>
                <input [type]="inputType" formControlName="passwordConfirm" matInput required>
                <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
                    <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                    <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                </button>
                <mat-error *ngIf="form.get('passwordConfirm').hasError('required')">Hasło jest wymagane</mat-error>
                <mat-error *ngIf="form.get('passwordConfirm').hasError('identicalField')">Hasła nie są identyczne</mat-error>
            </mat-form-field>

            <button [disabled]="isSubmitted" class="mt-2" color="primary" mat-raised-button>
                AKTYWUJ KONTO
            </button>
        </form>

        <div *ngIf="confirm && isValid" class="p-6 flex flex-col">
            <div class="text-center mt-4">
                <h4 class="body-2 text-secondary m-0">Konto zostało aktywowane</h4>
            </div>
            <button [routerLink]="['/login']" class="mt-8" color="primary" mat-raised-button type="button">
                ZALOGUJ SIĘ
            </button>
        </div>
    </div>
</div>
