import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {LoginComponent} from "./auth/login/login.component";
import {RegisterClientComponent} from "./auth/register-client/register-client.component";
import {authGuard} from "./guard/auth.guard";
import {LogoutComponent} from "./auth/logout/logout.component";
import {NotFoundComponent} from "./errors/not-found/not-found.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./auth/reset-password/reset-password.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {UserVerificationComponent} from "./auth/user-verification/user-verification.component";

const routes: Routes = [
  { path: 'login',  component: LoginComponent, children: [] },
  { path: 'logout',  component: LogoutComponent, canActivate: [authGuard], children: [] },
  { path: 'register',  component: RegisterClientComponent, children: [] },
  { path: 'forgot-password',  component: ForgotPasswordComponent, children: [] },
  { path: 'reset-password',  component: ResetPasswordComponent, children: [] },
  { path: 'user-verification',  component: UserVerificationComponent, children: [] },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [authGuard],
    children: [
      {path: '', component: DashboardComponent, pathMatch: 'full'},
      {
        path: 'clients',
        loadChildren: () => import('./client/accounting-clients/accounting-clients.module').then(m => m.AccountingClientsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'my-company',
        loadChildren: () => import('./client/company-data/company-data.module').then(m => m.CompanyDataModule),
      },
      {
        path: 'company-settings',
        loadChildren: () => import('./company-settings/company-settings.module').then(m => m.CompanySettingsModule),
      },
      {
        path: 'employees',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
      },
      {
        path: 'absences',
        loadChildren: () => import('./tenant/absence/absence.module').then(m => m.AbsenceModule),
      },
      {
        path: 'recruitment',
        loadChildren: () => import('./recruitment/recruitment.module').then(m => m.RecruitmentModule),
      },
      {
        path: 'projects',
        loadChildren: () => import('./tenant/project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'delegations',
        loadChildren: () => import('./tenant/delegation/delegation.module').then(m => m.DelegationModule),
      },
    ]
  },
  { path: '**',  component: NotFoundComponent, children: [] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
