<app-table
        [tableMenu]="tableMenu"
        [rowMenu]="rowMenu"
        [tableColumns]="tableColumns"
        [title]="title"
        [tableData]="data"
        (createElement)="edit(null)"
        (openElement)="edit($event)"
        (setTableData)="loadData($event)"
></app-table>

<mat-menu xPosition="before" yPosition="below">
    <ng-template matMenuContent let-row="row">
        <button mat-menu-item (click)="edit(row.id)">
            <mat-icon *ngIf="!row.archived" svgIcon="mat:edit"></mat-icon>
            <mat-icon *ngIf="row.archived" svgIcon="mat:preview"></mat-icon>
            <span>{{row.archived ? "Podgląd" : "Edytuj"}}</span>
        </button>

        <button mat-menu-item (click)="resetPassword(row.username)" *ngIf="!row.archived">
            <mat-icon svgIcon="mat:password"></mat-icon>
            <span>Resetuj hasło</span>
        </button>

        <button mat-menu-item (click)="toggleActivityStatus(row)" *ngIf="!row.archived">
            <mat-icon *ngIf="row.enabled" svgIcon="mat:lock"></mat-icon>
            <mat-icon *ngIf="!row.enabled" svgIcon="mat:lock_open"></mat-icon>
            <span>{{ row.enabled ? 'Dezaktywuj' : 'Aktywuj' }}</span>
        </button>

        <button mat-menu-item (click)="archive(row.id)" *ngIf="!row.archived">
            <mat-icon svgIcon="mat:archive"></mat-icon>
            <span>Archiwizuj</span>
        </button>

        <button mat-menu-item (click)="restore(row.id)" *ngIf="row.archived">
            <mat-icon svgIcon="mat:unarchive"></mat-icon>
            <span>Przywróć</span>
        </button>
    </ng-template>
</mat-menu>