<div @stagger class="max-w-3xs w-full">
    <div class="h-14 mb-6 flex px-gutter sm:px-0 flex items-center">
        <button (click)="openAddNew.emit()" class="flex-auto" color="primary" mat-raised-button type="button">
            <mat-icon class="ltr:-ml-4 ltr:mr-2 rtl:ml-2 icon-sm" svgIcon="mat:add"></mat-icon>
            <span>{{ createLabel || 'Dodaj' }}</span>
        </button>
    </div>

    <div class="px-gutter sm:px-0">
        <ng-container *ngFor="let item of items">
            <a (click)="setFilter(item.id)"
               *ngIf="item.type === 'link'"
               @fadeInRight
               [ngClass]="{ 'bg-primary/10 text-primary': isActive(item.id) }"
               class="rounded h-10 px-4 cursor-pointer hover:bg-primary/10 mt-1 font-medium flex items-center transition duration-200 ease-out"
               matRipple>
                <mat-icon [ngClass]="item.classes?.icon" [svgIcon]="item.icon" class="ltr:mr-3 rtl:ml-3"></mat-icon>
                <span>{{ item.label }}</span>
            </a>

            <h3 *ngIf="item.type === 'subheading'"
                @fadeInRight
                class="caption text-secondary font-medium mb-0 mt-6">{{ item.label }}</h3>
        </ng-container>
    </div>
</div>
